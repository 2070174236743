import React from "react";
import "./ProjectItem.sass";
import { BsSearch } from "react-icons/bs";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "../../features/modalSlice";
import { useNavigate } from "react-router-dom";

const ProjectItem = ({ item }) => {
  const isAuth = useSelector((store) => store.auth.isAuth);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleEdit = () => {
    navigate({
      pathname: "/admin/create",
      search: `?editById=${item.id}`,
    });
  };

  return (
    <div className="project-item">
      <div className="project-item-img">
        <img src={item.avatar} alt={item.name} />
        <h3>{item.name}</h3>
        <p>{item.type}</p>
        {isAuth && (
          <Button
            type="button"
            style={{
              position: "absolute",
              bottom: "20px",
              right: "0",
              zIndex: "999",
            }}
            onClick={handleEdit}
          >
            Edit
          </Button>
        )}
        <div className="project-item-blur"></div>
      </div>
      <div className="project-item-link">
        <Button onClick={() => dispatch(setOpen({ item }))}>
          <BsSearch />
        </Button>
      </div>
    </div>
  );
};

export default ProjectItem;
