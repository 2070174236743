import { Box, Grid } from "@mui/material";
import React from "react";

import "./RowData.sass";
import { Masonry } from "@mui/lab";
import { useSelector } from "react-redux";

const RowData = (props) => {
  const { setImageURL } = props;
  const widthScreen = useSelector((store) => store.pageControl.widthScreen);
  return (
    <Grid container className="popup-row">
      <Grid item xs={12}>
        {props.type === "row" && (
          <Box>
            {props.content?.type === "text" ? (
              <p style={{ margin: "6px 0" }}>{props.content.content}</p>
            ) : (
              <img
                style={{ width: "100%", marginTop: "4px" }}
                src={props.content.content}
                alt=""
              />
            )}
          </Box>
        )}
        {props.type === "layout" && (
          <Box>
            <Masonry sx={{ width: "auto" }} columns={props.content.type}>
              {props.content.content.map((x) => (
                <Box key={x.id}>
                  {x.content.type === "text" && <p>{x.content.content}</p>}
                  {x.content.type === "image" && (
                    <>
                      {widthScreen > 900 ? (
                        <img
                          style={{ width: "100%", marginTop: "4px" }}
                          src={x.content.content}
                          alt=""
                          onClick={() => setImageURL(x.content.content)}
                        />
                      ) : (
                        <img src={x.content.content} alt="" />
                      )}
                    </>
                  )}
                </Box>
              ))}
            </Masonry>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default RowData;
