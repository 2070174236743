import React from 'react'
import { useState } from 'react'

import "./CarouselImage.sass"

const CarouselImage = (props) => {
    const { item } = props
    const [isVertical, setIsVertical] = useState(false)

    const handleImage = (e) => {
        if (e.target?.width < e.target?.height) setIsVertical(true)
    }

    return (
        <div className={`popup-image ${isVertical && "vertical-image"}`}>
            <img src={item} alt="" onLoad={handleImage} />
        </div>
    )
}

export default CarouselImage