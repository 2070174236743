import React from "react";
import "./SectionTitle.sass"
const SectionTitle = ({ mainTitle, subTitle }) => {
  return (
    <h1 className="section-title">
      {mainTitle.toUpperCase()} <span>{subTitle.toUpperCase()}</span>
    </h1>
  );
};

export default SectionTitle;
