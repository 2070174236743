import React from "react";
import Carousel from "react-material-ui-carousel";
import { useSelector } from "react-redux";

import "./Banner.sass";

const Item = ({ item }) => {
  return (
    <div className="carousel-item">
      <div className="carousel-item-text">
        <div style={{ position: "relative", zIndex: "999" }}>
          <h1>{item.name}</h1>
          <h5>{item.description}</h5>
        </div>
        <div className="carousel-item-text-blur"></div>
      </div>
      <div className="carousel-item-img">
        <img src={item.banner} alt={item.name} />
        <div className="carousel-item-blur"></div>
      </div>
    </div>
  );
};

const Banner = () => {
  const projectList = useSelector((store) => store.project.projectList);

  return (
    <Carousel
      className="carousel"
      animation="fade"
      duration={500}
      interval={3000}
      indicators={false}
      navButtonsWrapperProps={{
        style: {
          left: "calc(100% - 100px)",
          top: "calc(100% - 40px)",
          width: "fit-content",
          height: "fit-content",
          visible: 1,
        },
      }}
      navButtonsProps={{
        style: {
          backgroundColor: "unset",
          borderRadius: 0,
          opacity: 1,
        },
      }}
    >
      {projectList.map((item, index) => {
        return <Item item={item} key={index} />;
      })}
    </Carousel>
  );
};

export default Banner;
