import { Box, Button, Grid, TextField, TextareaAutosize } from '@mui/material'
import React, { useEffect, useState } from 'react'

const BlockData = (props) => {
    const { id, content, handleCompleteBlock, handleDeleteBlock } = props
    const [data, setData] = useState({
        type: undefined,
        content: undefined
    })

    const resetData = () => {
        setData({ type: undefined, content: undefined })
    }

    useEffect(() => {
        if (data.content) {
            handleCompleteBlock(id, data)
        }
        // eslint-disable-next-line
    }, [data])

    useEffect(() => {
        if (content && !data.content) {
            setData({
                type: content.type,
                content: content.content
            })
        }
    }, [content, data.content])


    return (
        <Box width={"100%"}>
            {!data.type && <Grid container>
                <Button onClick={() => setData({ ...data, type: "text" })}>Add Text</Button>
                <Button onClick={() => setData({ ...data, type: "image" })}>Add Image</Button>
            </Grid>}
            {data.type === "text" && <TextareaAutosize value={data.content} onChange={e => setData({ ...data, content: e.target.value })} />}
            {data.type === "image" && <Grid>
                <TextField sx={{ borderColor: "white" }} value={data.content} onChange={e => setData({ ...data, content: e.target.value })} />
                {data.content && <img src={data.content} alt="" />}
            </Grid>}
            {data.content && <Grid>
                <Button onClick={() => resetData()}>Discard</Button>
                <Button onClick={() => handleDeleteBlock(id)}>Delete</Button>
            </Grid>}
        </Box>
    )
}

export default BlockData