import React, { useEffect } from "react";
import "./Admin.sass";
import { useDispatch, useSelector } from "react-redux";
import { setUserList } from "../../features/authSlice";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { instance } from "../../axios_config";
import { Link } from "react-router-dom";
const Admin = () => {
  const userList = useSelector((store) => store.auth.userList);
  const token = useSelector((store) => store.auth.token)
  const dispatch = useDispatch();
  useEffect(() => {
    if (userList.length === 0 && token) {
      instance
        .get("/user")
        .then((x) => {
          dispatch(setUserList(x.data));
        });
    }

    // eslint-disable-next-line
  }, [token]);

  return (
    <div className="admin">
      <Grid
        container
        maxWidth="md"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 5 }}>
          <Link to="create">
            <Button>Create Project</Button>
          </Link>
        </Box>
        {userList?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Index</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userList.map((item, index) => (
                  <TableRow
                    key={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{index}</TableCell>
                    <TableCell>{item.userName}</TableCell>
                    <TableCell>{item.phoneNumber}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.message}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <h1>No user</h1>
        )}
      </Grid>
    </div >
  );
};

export default Admin;
