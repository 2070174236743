import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, styled } from "@mui/material";
import RowData from "./RowData/RowData";
import { instance } from "../../../axios_config";
import { useForm } from "react-hook-form";
import "./Create.sass";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProject } from "../../../features/projectSlice";

const API_ENDPOINT_CREATE = "/project/create";
const API_ENDPOINT_UPDATE = "/project/update";
const API_ENDPOINT_DELETE = "/project/delete";

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props,
})((p) => ({
  marginBottom: "1rem",
  input: {
    height: "auto",
    width: "100%",
    color: "var(--bg-accent)",
  },
  textarea: {
    height: "100% !important",
    width: "100%",
    color: "var(--bg-accent)",
  },
  fieldset: {
    borderColor: "var(--bg-opacity-white)",
    "&:hover": {
      borderColor: "var(--bg-opacity-white)",
    },
  },
  label: {
    color: "var(--bg-opacity-white)",
  },

  "&:hover": {
    label: {
      color: "var(--bg-accent)",
    },
  },

  // input label when focused
  "& label.Mui-focused": {
    color: "var(--bg-accent)",
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "white",
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      fieldset: {
        borderColor: "var(--bg-accent)",
      },
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--bg-accent)",
    },
  },

  "& .MuiFormHelperText-root": {
    color: "var(--bg-accent)",
  },

  "& .MuiInputBase-multiline": {
    height: "100%",
    textarea: {
      lineHeight: "100%",
    },
  },
}));

const Create = () => {
  const { register, handleSubmit, reset, setValue } = useForm();

  const [avatar, setAvatar] = useState("");
  const [banner, setBanner] = useState("");

  const [rowDatas, setRowDatas] = useState([]);

  const projectOnEdit = useSelector((store) => store.project.projectOnEdit);
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const projectId = searchParams.get("editById");
    if (projectId) {
      dispatch(fetchProject(projectId));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectOnEdit) {
      setAvatar(projectOnEdit.avatar);
      setBanner(projectOnEdit.banner);
      setRowDatas(JSON.parse(projectOnEdit.content));
      setValue("name", projectOnEdit.name);
      setValue("description", projectOnEdit.description);
      setValue("type", projectOnEdit.type);
    }
    // eslint-disable-next-line
  }, [projectOnEdit]);

  const handleDeleteRow = (rowId) => {
    setRowDatas(rowDatas.filter((row) => row.id !== rowId));
  };

  const handleCompleteRow = (id, content) => {
    let list = [...rowDatas];
    const indexOfRow = list.findIndex((x) => x.id === id);
    list[indexOfRow] = {
      ...list[indexOfRow],
      content: content,
    };

    setRowDatas([...list]);
  };

  const handleCreate = (data) => {
    let projectData = {
      ...data,
      avatar,
      banner,
      content: JSON.stringify(rowDatas),
    };

    if (searchParams.get("editById")) {
      instance
        .post(API_ENDPOINT_UPDATE + "/" + searchParams.get("editById"), {
          ...projectData,
        })
        .then((x) => {
          resetData();
        });
    } else {
      instance
        .post(API_ENDPOINT_CREATE, {
          ...projectData,
        })
        .then((x) => {
          resetData();
        });
    }
  };

  const handleDeleteProject = () => {
    instance
      .post(API_ENDPOINT_DELETE + "/" + searchParams.get("editById"))
      .then((x) => {
        resetData();
      });
  };

  const resetData = () => {
    reset();
    setRowDatas([]);
  };

  return (
    <div className="create">
      <form className="create-form" onSubmit={handleSubmit(handleCreate)}>
        <CssTextField
          label="Project Name"
          variant="outlined"
          {...register("name")}
        />
        <CssTextField
          label="Short Description"
          variant="outlined"
          {...register("description")}
        />
        <CssTextField
          label="Project Type"
          variant="outlined"
          {...register("type")}
        />

        <Box display={"flex"} flexDirection={"column"}>
          {
            <Grid container spacing={2}>
              <Grid item xs={6} display={"flex"} flexDirection={"column"}>
                <CssTextField
                  label="landscape image for banner"
                  variant="outlined"
                  value={banner}
                  onChange={(e) => setBanner(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                {banner && <img src={banner} alt="banner" />}
              </Grid>
            </Grid>
          }
          {
            <Grid container spacing={2}>
              <Grid item xs={6} display={"flex"} flexDirection={"column"}>
                <CssTextField
                  label="portrait image for avatar"
                  variant="outlined"
                  value={avatar}
                  onChange={(e) => setAvatar(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                {avatar && <img src={avatar} alt="avatar" />}
              </Grid>
            </Grid>
          }
        </Box>

        {rowDatas.length > 0 && (
          <Grid
            width={"100%"}
            maxWidth="lg"
            minWidth="md"
            margin="auto"
            display={"flex"}
            flexDirection={"column"}
            spacing={3}
            container
          >
            {rowDatas.map((row) => (
              <RowData
                key={row.id}
                {...row}
                handleCompleteRow={handleCompleteRow}
                handleDeleteRow={handleDeleteRow}
              />
            ))}
          </Grid>
        )}
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="button"
            onClick={() =>
              setRowDatas([
                ...rowDatas,
                {
                  id: Date.now(),
                  type: "row",
                },
              ])
            }
          >
            Create a Row
          </Button>
          <Button
            type="button"
            onClick={() =>
              setRowDatas([
                ...rowDatas,
                {
                  id: Date.now(),
                  type: "layout",
                },
              ])
            }
          >
            Create a layout
          </Button>
          <Button
            type="button"
            onClick={() =>
              setRowDatas([
                ...rowDatas,
                {
                  id: Date.now(),
                  type: "pdf",
                },
              ])
            }
          >
            Add PDF
          </Button>
          {searchParams.get("editById") ? (
            <>
              <Button type="submit">Update</Button>
              <Button type="button" onClick={handleDeleteProject}>
                Delete
              </Button>
            </>
          ) : (
            <Button type="submit">Create</Button>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default Create;
