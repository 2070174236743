import { configureStore } from "@reduxjs/toolkit";
import pageControlReducer from "./features/pageControlSlice";
import authReducer from "./features/authSlice";
import projectReducer from "./features/projectSlice";
import modalReducer from "./features/modalSlice";
import spinnerReducer from "./features/spinnerSlice"

export const store = configureStore({
  reducer: {
    pageControl: pageControlReducer,
    auth: authReducer,
    project: projectReducer,
    modal: modalReducer,
    spinner: spinnerReducer
  },
});
