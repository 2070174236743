import { Button, Grid, TextField, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";

import "./RowData.sass";
import styled from "@emotion/styled";
import { Masonry } from "@mui/lab";
import BlockData from "./BlockData/BlockData";

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props,
})((p) => ({
  marginBottom: "1rem",
  input: {
    height: "auto",
    width: "100%",
    color: "var(--bg-accent)",
  },
  textarea: {
    height: "100% !important",
    width: "100%",
    color: "var(--bg-accent)",
  },
  fieldset: {
    borderColor: "var(--bg-opacity-white)",
    "&:hover": {
      borderColor: "var(--bg-opacity-white)",
    },
  },
  label: {
    color: "var(--bg-opacity-white)",
  },

  "&:hover": {
    label: {
      color: "var(--bg-accent)",
    },
  },

  // input label when focused
  "& label.Mui-focused": {
    color: "var(--bg-accent)",
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "white",
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      fieldset: {
        borderColor: "var(--bg-accent)",
      },
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--bg-accent)",
    },
  },

  "& .MuiFormHelperText-root": {
    color: "var(--bg-accent)",
  },

  "& .MuiInputBase-multiline": {
    height: "100%",
    textarea: {
      lineHeight: "100%",
    },
  },
}));

const RowData = (props) => {
  const { id, type, handleCompleteRow, handleDeleteRow, content } = props;
  const [data, setData] = useState({
    type: undefined,
    content: undefined
  });


  useEffect(() => {
    if (data.content) {
      handleCompleteRow(id, data)
    }
    // eslint-disable-next-line
  }, [data.content])

  useEffect(() => {
    if (content) {
      setData({
        type: content.type,
        content: content.content
      })
    }
  }, [content])

  const resetData = () => {
    setData({
      type: undefined,
      content: false
    });
  };

  const handleDeleteBlock = (blockId) => {
    setData({ ...data, content: [...data.content].filter(x => x.id !== blockId) })
  }

  const handleCompleteBlock = (blockId, blockContent) => {
    let list = [...data.content];
    const indexOfBlock = list.findIndex((x) => x.id === blockId);
    list[indexOfBlock] = {
      ...list[indexOfBlock],
      content: blockContent,
    };

    setData({ ...data, content: [...list] })
  }

  return (
    <>
      {
        type === "row" && <Grid
          container
          sx={{ width: "100%", minHeight: "10vh" }}
          flexDirection={"row"}
        >
          {data.type === undefined && <Grid container sx={{ display: "flex" }}>
            <Button onClick={() => setData({ ...data, type: "text" })}>
              Add text
            </Button>
            <Button onClick={() => setData({ ...data, type: "image" })}>
              Add image
            </Button>
          </Grid>}
          {data.type === "text" && <Grid container>
            <TextareaAutosize fullwidth="true" label="Add content for this row" value={data.content} onChange={(e) => setData({ ...data, content: e.target.value })} />
          </Grid>}
          {data.type === "image" && <Grid container>
            <CssTextField fullwidth="true" label="Add image" value={data.content} onChange={(e) => setData({ ...data, content: e.target.value })} />
            {
              data.content && <img src={data.content} alt="" />
            }
          </Grid>}
          <Button onClick={() => resetData()}>Discard</Button>
          <Button onClick={() => handleDeleteRow(id)}>Delete</Button>
        </Grid>
      }
      {
        type === "layout" && <Grid container>
          {!data.type && <>
            <Button onClick={() => setData({ ...data, type: 2 })}>2 Col</Button>
            <Button onClick={() => setData({ ...data, type: 3 })}>3 Col</Button>
            <Button onClick={() => setData({ ...data, type: 4 })}>4 Col</Button>
          </>}
          {data.type && <>
            {data.content === undefined ? <Button onClick={() => setData({ ...data, content: [{ id: Date.now() }] })}>Add new Block</Button>
              :
              <Button onClick={() => setData({ ...data, content: [...data.content, { id: Date.now() }] })}>Add new Block</Button>
            }
          </>}
          {
            data.content && <Masonry columns={data.type} spacing={3}>
              {data?.content?.map(block => <BlockData key={block.id}
                {...block}
                handleCompleteBlock={handleCompleteBlock}
                handleDeleteBlock={handleDeleteBlock}
              />
              )}
            </Masonry>
          }
        </Grid>
      }
      {
        type === "pdf" && <Grid container>
            <TextareaAutosize fullwidth="true" label="PDF link" value={data.content} onChange={(e) => setData({ ...data, content: e.target.value })} />
        </Grid>
      }
    </>
  );
};

export default RowData;
