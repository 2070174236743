import {
  Box,
  Container,
  Typography,
  Button,
  styled,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { instance } from "../../axios_config";
import { setAuth, setToken } from "../../features/authSlice";
import { useNavigate } from "react-router-dom";
import { decodeToken, getTokenFromLocalStorage } from "../../helpers/tokenUtil";

const API_ENDPOINT = "/oauth/login";

const Login = () => {
  const { register, handleSubmit } = useForm();

  const isAuth = useSelector((store) => store.auth.isAuth);
  //eslint-disable-next-line

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      const token = getTokenFromLocalStorage()
      if (token) {
        const decodedToken = decodeToken(token);
        const isExpired =
          new Date(decodedToken.exp * 1000).getDate() < new Date().getDate();
        if (!isExpired && decodedToken.sub === "admin") {
          navigate("/admin");
        }
        dispatch(setAuth());
      }
    }
    // eslint-disable-next-line
  }, []);

  const onSubmitForm = (data) => {
    instance.post(API_ENDPOINT, { ...data }).then((x) => {
      const resToken = x.headers.jwt;
      if (resToken) {
        dispatch(setToken(resToken))
        dispatch(setAuth());
        navigate("/admin");
      }
    });
  };

  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor",
  })((p) => ({
    marginBottom: "1rem",
    input: {
      height: "auto",
      width: "100%",
      color: "var(--bg-accent)",
    },
    fieldset: {
      borderColor: "var(--bg-opacity-white)",
      "&:hover": {
        borderColor: "var(--bg-opacity-white)",
      },
    },
    label: {
      color: "var(--bg-opacity-white)",
    },

    "&:hover": {
      label: {
        color: "var(--bg-accent)",
      },
    },

    // input label when focused
    "& label.Mui-focused": {
      color: "var(--bg-accent)",
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "white",
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&:hover": {
        fieldset: {
          borderColor: "var(--bg-accent)",
        },
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--bg-accent)",
      },
    },

    "& .MuiFormHelperText-root": {
      color: "var(--bg-accent)",
    },
  }));
  return (
    <Container
      component="main"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ width: "20rem" }}>
        <Typography component="h1" variant="h5" mb={3}>
          Login Di Em
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmitForm)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CssTextField
            sx={{ width: "100%" }}
            label="User name"
            name="userName"
            type="text"
            {...register("userName", { required: true })}
          />
          <CssTextField
            sx={{ width: "100%" }}
            name="password"
            label="Password"
            type="password"
            {...register("password", { required: true })}
          />

          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
