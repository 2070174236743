import { Grid } from "@mui/material";
import React from "react";
import location from "../../assets/images/location.png";
import "./Footer.sass";
const Footer = () => {
  return (
    <Grid container className="footer">
      <Grid item xs={8} sx={{ margin: "auto" }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <p>tu.trancong@outlook.com</p>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="location">
              <img src={location} alt="location" />
              Toronto, Canada
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
