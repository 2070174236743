import React, { useRef } from "react";
import SectionTitle from "../SectionTitle/SectionTitle";
import { Button, Grid, TextField, styled } from "@mui/material";
import { useForm } from "react-hook-form";
import "./ContactForm.sass";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props,
})((p) => ({
  marginBottom: "1rem",
  input: {
    height: "auto",
    width: "100%",
    color: "var(--bg-accent)",
  },
  textarea: {
    height: "100% !important",
    width: "100%",
    color: "var(--bg-accent)",
  },
  fieldset: {
    borderColor: "var(--bg-opacity-white)",
    "&:hover": {
      borderColor: "var(--bg-opacity-white)",
    },
  },
  label: {
    color: "var(--bg-opacity-white)",
  },

  "&:hover": {
    label: {
      color: "var(--bg-accent)",
    },
  },

  // input label when focused
  "& label.Mui-focused": {
    color: "var(--bg-accent)",
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "white",
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      fieldset: {
        borderColor: "var(--bg-accent)",
      },
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--bg-accent)",
    },
  },

  "& .MuiFormHelperText-root": {
    color: "var(--bg-accent)",
  },

  "& .MuiInputBase-multiline": {
    height: "100%",
    textarea: {
      lineHeight: "100%",
    },
  },
}));

const ContactForm = () => {
  const { register, handleSubmit, reset } = useForm();
  const form = useRef();
  const onSubmitForm = () => {
    emailjs
      .sendForm(
        "service_8jy2xbd",
        "template_7r1p7jp",
        form.current,
        "lFozzd07Z4gEuDCLb"
      )
      .then(
        (result) => {
          toast.success("Email contact was sent successfully!", {
            style: {
              color: "black",
            },
          });
          reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div id="contact-form" className="contact-form">
      <SectionTitle mainTitle="Get" subTitle="In Touch" />
      <Grid container maxWidth="xl" sx={{ margin: "auto" }}>
        <Grid item xs={8} sx={{ margin: "auto" }}>
          <form ref={form} onSubmit={handleSubmit(onSubmitForm)}>
            <Grid container spacing={{ xs: 0, md: 2 }}>
              <Grid item xs={12} md={5}>
                <CssTextField
                  fullWidth
                  type="text"
                  label="Full Name"
                  {...register("name", { required: true })}
                />
                <CssTextField
                  fullWidth
                  type="text"
                  label="Phone"
                  {...register("phone_number", { required: true })}
                />
                <CssTextField
                  fullWidth
                  type="email"
                  label="Email"
                  {...register("email", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={7} display={"flex"}>
                <CssTextField
                  multiline
                  fullWidth
                  type="text"
                  label="Your message"
                  helperText=""
                  {...register("note")}
                  sx={{ minHeight: "6rem" }}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              width="auto"
              variant="outlined"
              color="inherit"
              sx={{ marginTop: "2rem", padding: ".8rem" }}
            >
              Send
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactForm;
