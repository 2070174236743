import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  project: null,
  imageList: [],
};

export const modal = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setOpen: (state, action) => {
      state.isOpen = true;
      state.project = {
        ...action.payload.item,
        content: JSON.parse(action.payload.item.content),
      };
      const images = [action.payload.banner, action.payload.avatar];
      const splitListImages = action.payload.item.content.split(",");
      splitListImages.forEach((x, index) => {
        if (x.includes("image")) {
          images.push(splitListImages[index + 1].split('"')[3]);
        }
      });
      state.imageList = images;
    },
    setClose: (state, action) => {
      state.isOpen = false;
      state.project = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { isOpen, project, setOpen, setClose } = modal.actions;

export default modal.reducer;
