import { Button, Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./PopupModal.sass";
import RowData from "./RowData/RowData";
import { useMemo } from "react";
import Carousel from "react-material-ui-carousel";
import CarouselImage from "./CarouselImage/CarouselImage";
import { setClose } from "../../features/modalSlice";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "90%",
  backgroundColor: "#ffffffc2",
  overflowY: "scroll",
  p: 4,
};

const PopupModal = () => {
  const isOpen = useSelector((store) => store.modal.isOpen);
  const project = useSelector((store) => store.modal.project);
  const imageList = useSelector((store) => store.modal.imageList);

  const dispatch = useDispatch();

  const [imageURL, setImageURL] = useState(null);

  const imgIndex = useMemo(() => {
    return imageList.findIndex((x) => x === imageURL);
  }, [imageURL, imageList]);

  return (
    <Modal
      open={isOpen}
      className="popup"
      onClose={() => {
        dispatch(setClose());
      }}
    >
      <Grid
        container
        style={style}
        className="popup-container"
        p={{ xs: "2rem", md: "3rem" }}
      >
        <Grid container>
          {imageURL ? (
            <Carousel
              className="carousel"
              animation="fade"
              duration={500}
              autoPlay={false}
              index={imgIndex}
              interval={3000}
              indicators={false}
              navButtonsWrapperProps={{
                style: {
                  top: "-50%",
                },
              }}
              navButtonsProps={{
                className: "nav-button",
                style: {
                  backgroundColor: "unset",
                  borderRadius: 0,
                  opacity: 1,
                },
              }}
            >
              {imageList.map((item, index) => {
                return <CarouselImage item={item} key={index} />;
              })}
            </Carousel>
          ) : (
            <Grid container flexDirection={"column"}>
              <Grid container>
                {project?.content[0]?.type === "pdf" ? (
                  <div
                    style={{ width: "100%" }}
                    dangerouslySetInnerHTML={{
                      __html: project?.content[0]?.content?.content,
                    }}
                  ></div>
                ) : (
                  project?.content.map((row) => (
                    <RowData key={row.id} {...row} setImageURL={setImageURL} />
                  ))
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <>
          {imageURL ? (
            <Button
              style={{ background: "unset", position: "absolute" }}
              onClick={() => setImageURL(null)}
            >
              <KeyboardBackspaceIcon style={{ fill: "black" }} />
            </Button>
          ) : (
            <Button
              style={{
                background: "unset",
                position: "fixed",
                top: "0",
                right: "10px",
              }}
              onClick={() => dispatch(setClose())}
            >
              <CloseIcon style={{ fill: "black" }} />
            </Button>
          )}
        </>
      </Grid>
    </Modal>
  );
};

export default PopupModal;
