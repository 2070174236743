import React from "react";
import { ContactForm } from "../../components";
import Banner from "./Banner/Banner";
import "./Home.sass";
import Project from "./Project/Project";

const Home = () => {
  return (
    <div id="home">
      <Banner />
      <Project />
      <ContactForm />
    </div>
  );
};

export default Home;
