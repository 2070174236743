import { Grid } from "@mui/material";
import React from "react";

import "./Portfolio.sass";
import ProjectItem from "../../components/ProjectItem/ProjectItem";
import { ContactForm } from "../../components";
import { useSelector } from "react-redux";
import BACKDROP_IMG from "../../assets/images/project/project.jpg";

const Portfolio = () => {
  const projectList = useSelector((store) => store.project.projectList);
  return (
    <div className="portfolio">
      <Grid
        container
        sx={{
          m: "auto",
          height: { xs: "40vh", md: "70vh" },
          mb: "4rem",
          backgroundImage: `url(${BACKDROP_IMG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center 70%",
          position: "relative",
        }}
      >
        <Grid item xs={3}></Grid>
        <Grid
          item
          xs={7}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            zIndex: "1",
            marginBottom: {
              xs: 2,
              md: 3,
            },
            marginLeft: 3,
          }}
        >
          <h1 className="portfolio-header">PROJECTS</h1>
        </Grid>
        <div className="portfolio-blur"></div>
      </Grid>
      <Grid
        container
        maxWidth="xl"
        spacing={{ xs: 1, lg: 0 }}
        justifyContent={"center"}
        sx={{ margin: "auto !important" }}
      >
        {projectList.map((item, index) => {
          return (
            <Grid item xs={6} md={4} lg={3} key={index}>
              <ProjectItem item={item} />
            </Grid>
          );
        })}
      </Grid>
      <ContactForm />
    </div>
  );
};

export default Portfolio;
