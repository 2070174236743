import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../axios_config";

export const fetchProjectList = createAsyncThunk('project/fetchProjectList', async () => {
  const res = await instance.get('/project')
  const data = await res.data
  return data
})

export const fetchProject = createAsyncThunk('project/fetchProject', async (projectId) => {
  const res = await instance.get(`/project/${projectId}`)
  const data = await res.data
  return data
})

const initialState = {
  projectList: [],
  projectOnEdit: null
};

export const project = createSlice({
  name: "project",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectList.fulfilled, (state, action) => {
      state.projectList = action.payload
    });
    builder.addCase(fetchProject.fulfilled, (state, action) => {
      state.projectOnEdit = action.payload
    })
  }

});

// Action creators are generated for each case reducer function
export const { projectList, projectOnEdit } = project.actions;

export default project.reducer;
