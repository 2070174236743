import React from "react";

import BANNER_IMG from "../../assets/images/about/banner.jpg";
import AVATAR_IMG from "../../assets/images/about/avatar.jpg";
import INTERIOR_IMG from "../../assets/images/home_about/interiors.png";
import ARCHITECTURE_IMG from "../../assets/images/home_about/architecture.png";
import PLANING_IMG from "../../assets/images/home_about/planing.png";
import THREEDRENDERING from "../../assets/images/home_about/3d.png";
import { Grid } from "@mui/material";
import { ContactForm } from "../../components";
import VRAY_IMG from "../../assets/images/vray.png";
import AUTO_CAD_IMG from "../../assets/images/autocad.png";
import { SiSketchup } from "react-icons/si";
import { DiPhotoshop } from "react-icons/di";

import "./About.sass";

const skills = [
  {
    title: "INTERIOR DESIGN",
    img: INTERIOR_IMG,
    text: "Elevate spaces with our signature interior designs. We blend style and functionality to craft living and working areas that resonate with your vision and needs.",
  },
  {
    title: "ARCHITECTURE",
    img: ARCHITECTURE_IMG,
    text: "Transform ideas into structures. Our architectural expertise fuses innovation and aesthetics to create timeless designs that inspire and endure.",
  },
  {
    title: "SPACE PLANNING",
    img: PLANING_IMG,
    text: "Optimize your space for maximum efficiency. Our meticulous planning ensures layouts that make the most of every square foot, enhancing both functionality and aesthetics.",
  },
  {
    title: "3D RENDERING",
    img: THREEDRENDERING,
    text: "Visualize the future with precision. Our 3D rendering breathes life into concepts, empowering you to refine your projects while making informed decisions.",
  },
];

const About = () => {
  return (
    <div id="about">
      <div className="about">
        <div className="about-intro">
          <img src={BANNER_IMG} alt="banner.jpg" />
          <div className="about-intro-text">
            <p>Hello, My name is</p>

            <h1 style={{ margin: "0" }}>Tu Tran</h1>
            <p className="about-intro-text-des">
              <span> A</span> Freelance Designer, specialized in Interior
              Design, Planning, and 3D Rendering
            </p>
          </div>
        </div>
        <div className="about-avatar">
          <Grid maxWidth="lg" container sx={{ m: "auto", py: "4rem" }}>
            <Grid item xs={10} sx={{ m: "auto" }}>
              <Grid container>
                <Grid item xs={6}>
                  <div className="about-avatar-img">
                    <img src={AVATAR_IMG} alt="avatar" />
                    <div className="blur"></div>
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ pl: { xs: "1rem", md: "3rem" } }}>
                  <div className="about-description">
                    <h4>
                      <span>ABOUT ME</span>
                    </h4>
                    <h3>Who Am I</h3>
                    <p>
                      I am Tu Tran, a proficient Freelance Designer with
                      specialized expertise in Interior, Architecture, and
                      Planning. Currently in my third year at Georgian College,
                      pursuing a major in Interior Design, I possess a
                      comprehensive skill set essential for crafting,
                      visualizing, and conceptualizing spaces.
                      <br />
                      <br />
                      Additionally, I excel in the art of 3D rendering, enabling
                      clients to experience realistic previews of their
                      envisioned projects.
                    </p>
                    <div className="about-skill">
                      <Grid container spacing={1}>
                        <Grid item xs={3} sm={6} className="about-skill-item">
                          <DiPhotoshop />
                          <p>Photoshop</p>
                        </Grid>
                        <Grid item xs={3} sm={6} className="about-skill-item">
                          <SiSketchup />
                          <p>SketchUp</p>
                        </Grid>
                        <Grid item xs={3} sm={6} className="about-skill-item">
                          <img src={VRAY_IMG} alt="vray" />
                          <p>V-Ray</p>
                        </Grid>
                        <Grid item xs={3} sm={6} className="about-skill-item">
                          <img src={AUTO_CAD_IMG} alt="autoCad" />
                          <p>AutoCad</p>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="about-service">
          <Grid container sx={{ py: "4rem" }}>
            <Grid item xs={10} sx={{ m: "auto" }}>
              <div className="about-service-title">
                <h4>
                  <span>MY SERVICES</span>
                </h4>
                <h3>What I Can Do</h3>
              </div>
              <Grid
                container
                sx={{ width: "calc(100% + 2rem)", marginLeft: "-1rem" }}
              >
                {skills.map((item, index) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      md={3}
                      key={index}
                      sx={{
                        padding: "1rem",
                      }}
                    >
                      <SkillItem {...item} />
                    </Grid>
                  );
                })}
              </Grid>{" "}
            </Grid>
          </Grid>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

const SkillItem = (props) => {
  const { title, img, text } = props;
  return (
    <div className="about-service-item">
      <img src={img} alt={title} />
      <h5>{title}</h5>
      <p>{text}</p>
    </div>
  );
};

export default About;
