import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { decodeToken, getTokenFromLocalStorage } from "../../helpers/tokenUtil";
import { setAuth } from "../../features/authSlice";
import { Box } from "@mui/material";

const PrivateRoute = ({ children }) => {
  const isAuth = useSelector((store) => store.auth.isAuth);
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const token = getTokenFromLocalStorage()
    if (!isAuth) {
      if (!token) {
        setIsLoading(false);
        navigate("/login")
        return
      }
      const decodedToken = decodeToken(token);
      const isExpired = new Date(decodedToken.exp * 1000) < new Date();
      if (isExpired || decodedToken.sub !== "admin") {
        localStorage.removeItem("besToken");
        navigate("/login");
        setIsLoading(false);
      }
      dispatch(setAuth());
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [isAuth]);

  if (isLoading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Is Loading...
      </Box>
    );
  }

  return children;
};

export default PrivateRoute;
