import { createSlice } from "@reduxjs/toolkit";
import { getTokenFromLocalStorage } from "../helpers/tokenUtil";

const initialState = {
  isAuth: false,
  token: null,
  userList: [],
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = true;
    },
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    getToken: (state, action) => {
      state.token = getTokenFromLocalStorage();
    },
    setToken: (state, action) => {
      localStorage.setItem("besToken", action.payload);
    },
    signOut: (state, action) => {
      localStorage.removeItem("besToken");
      state.isAuth = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { isAuth, setAuth, setUserList, getToken, setToken, signOut } =
  auth.actions;

export default auth.reducer;
