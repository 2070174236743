import { useCallback, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.sass";
import { Footer, Navbar, PopupModal, ScrollButton } from "./components";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { getToken, setAuth } from "./features/authSlice";
import { setWidthScreen } from "./features/pageControlSlice";
import { fetchProjectList } from "./features/projectSlice";
import { decodeToken, getTokenFromLocalStorage } from "./helpers/tokenUtil";
import { About, Contacts, Home, Login, Portfolio } from "./pages";
import Admin from "./pages/Admin/Admin";
import Create from "./pages/Admin/Create/Create";

function App() {
  const projectList = useSelector((store) => store.project.projectList);
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const token = getTokenFromLocalStorage();
    if (token) {
      const isAdmin = decodeToken(token).sub === "admin";
      if (isAdmin) {
        dispatch(getToken());
        dispatch(setAuth());
      }
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setWidthScreen(window.innerWidth));
    if (projectList.length === 0) {
      dispatch(fetchProjectList());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    scroll.scrollToTop();
  }, [pathname]);

  const handleWindowResize = useCallback((event) => {
    dispatch(setWidthScreen(window.innerWidth));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <div className="app">
      <ToastContainer />
      <Navbar />
      <ScrollButton />
      <PopupModal />
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="portfolio" element={<Portfolio />}></Route>
        <Route path="contacts" element={<Contacts />} />
        <Route
          path="admin"
          element={<PrivateRoute children={<Admin />} />}
        ></Route>
        <Route
          path="admin/create"
          element={<PrivateRoute children={<Create />} />}
        ></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
