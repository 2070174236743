import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false
};

export const spinner = createSlice({
    name: "spinner",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { isLoading, setIsLoading } = spinner.actions;

export default spinner.reducer;
